<template>
  <div class="bg-grey-lightest rounded-medium px-4 pb-4 md:px-8 mb:pb-8 xl:px-44 xl:pb-14">
    <header class="container-header flex items-center justify-between py-4 md:py-8 xl:py-7 mb-4 md:mb-9 border-b border-gray-mediumLight">
      <h2 class="sub-title mb-0">Feed Mill</h2>

      <router-link
        class="btn-success"
        :to="{name: 'feed-create'}"
      >
        Create new feed mill
      </router-link>
    </header>

    <empty-feed-list
      v-if="dietsByAnimalType"
      class="max-w-lg"
      text="No feed mill created so far!"
    />

    <div
      v-else
      class="flex md:space-x-8 xl:space-x-12 text-grey-dark"
    >
      <div class="flex-1 lg:bg-grey-light lg:rounded-xl lg:py-9 lg:pr-3 lg:pl-6 xl:pl-9 xl:pr-6">
        <div class="lg:pr-3 mb-4 sort text-right">
          <label class="form-field text-sm">
            <span class="inline-block mr-5">
              Sort by:
            </span>

            <form-field-select
              v-model="sortQuery"
              class="inline-block"
              :options="sortOptions"
              :transparent="true"
            />
          </label>
        </div>

        <form-field-input
          v-model="searchQuery"
          size="large"
          placeholder="Search Feed Mills"
          class="mb-5 lg:pr-3 w-full text-red-400"
        >
          <template v-slot:icon>
            <i v-html="icons.search"></i>
          </template>
        </form-field-input>

        <ul
          class="lg:h-feeds-list flex flex-col space-y-5 lg:overflow-y-auto overflow-x-hidden lg:pr-3 max-h-feedList"
        >
          <li
            class="flex flex-wrap flex-row justify-between md:items-center bg-white rounded-xl py-3.5 px-4 md:px-5 xl:py-6 xl:px-7 cursor-pointer relative"
            v-for="diet in filteredDiets"
            :key="diet.id"
            :class="[isActiveDiet(diet.id) ? 'ring-inset ring-2 ring-brand-medium shadow-md' : '']"
            @click="localSetActiveDiet(diet)"
          >
            <div class="flex-1">
              <p class="name text-brand-dark text-medium leading-none mb-0.5">
                {{ isActiveDiet(diet.id) ? activeDiet.name : diet.name}}
              </p>

              <p class="date leading-none">
                <small>
                  Created Date:
                  {{diet.created_at | monthDate}}
                </small>
              </p>
            </div>

            <div class="flex justify-between items-center space-x-10">
              <div class="rounded-full border border-brand-dark py-2.5 px-8 text-xs text-brand-dark leading-none hidden md:flex items-center">
                Edit

                <span
                  v-html="icons.arrows.right"
                  class="icon w-1.5 ml-3"
                />
              </div>

              <span
                class="circle w-6 h-6 rounded-full border-2 border-brand-semiMedium text-center"
                :class="[isActiveDiet(diet.id) ? 'bg-brand-semiMedium' : '']"
              >
                <img
                  src="@/assets/images/icons/completed.svg"
                  class="w-2.5 h-2.5 mb-1 inline-block align-middle"
                  alt=""
                />
              </span>
            </div>

            <div
              v-if="isActiveDiet(diet.id)"
              class="lg:hidden w-full"
            >
              <feed-edit
                v-model="localDiet"
                label="name"
                save-button-title="Save for later"
                track-by="id"
                @save="onSave"
                @select="onSelectIngredient"
                @remove="onRemoveIngredient"
                @delete="onModalOpen"
              />
            </div>

            <div
              v-if="isActiveDiet(diet.id)"
              class="w-4 overflow-hidden hidden lg:block absolute -right-3.5 top-1/2 transform -translate-y-1/2"
            >
              <div class="h-5 bg-white rotate-45 transform origin-top-left border-2 border-brand-medium"></div>
            </div>
          </li>
        </ul>
      </div>

      <div class="flex-1 hidden lg:block">
        <empty-feed-list
          v-if="!Object.keys(activeDiet).length"
          text="Select feed mill to edit"
        />

        <feed-edit
          v-else
          v-model="localDiet"
          label="name"
          save-button-title="Save for later"
          track-by="id"
          @save="onSave"
          @select="onSelectIngredient"
          @remove="onRemoveIngredient"
          @delete="onModalOpen"
        />
      </div>
    </div>

    <modal
      ref="modal"
      :isOpen="isModalOpen"
      @close="onModalClose"
    >
      <template #title>
        <div class="flex flex-col md:flex-row justify-center md:justify-start items-center md:items-start">
          <div class="attention">
            <span class="icon" v-html="icons.attention"></span>
          </div>

          <h3 class="sub-title text-2xl m-0 text-left">
            You are about to delete <span class="font-medium">{{ activeDiet.name }}</span>
            <small>Are you sure you want to erase all the data that have been recorder in your account?</small>
          </h3>
        </div>
      </template>

      <template #button>
        <button class="remove" @click="onDelete">Delete Diet</button>
      </template>
    </modal>

    <Navigation
      v-bind="{
        prev: {disabled: false},
        next: {disabled: disabledNextButton}
      }"
      @click="onNavigationClick($event, 'home', 'phases')"
    />
  </div>
</template>

<script>
import EmptyFeedList from '@/components/FeedListEmpty';
import FeedEdit from '@/components/FeedEdit';
import FormFieldInput from '@/components/form/FormFieldInput';
import FormFieldSelect from '@/components/form/FormFieldSelect';
import Modal from '@/components/Modal';
import ModalMixin from '@/mixins/ModalMixin';
import WindowUnload from '@/mixins/WindowUnload';
import Navigation from '@/components/Navigation';
import NavigationMixin from '@/mixins/NavigationMixin';
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'FeedView',

  mixins: [
    ModalMixin,
    NavigationMixin,
    WindowUnload
  ],

  components: {
    FormFieldInput,
    FormFieldSelect,
    EmptyFeedList,
    FeedEdit,
    Modal,
    Navigation
  },

  filters: {
    monthDate(val) {
      return new Date(val).toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit'
      })
    }
  },

  data: () => ({
    searchQuery: '',
    sortQuery: 'dateDesc',
    sortOptions: [
      {value: 'dateAsc', label: 'Date Asc \u{2191}'},
      {value: 'dateDesc', label: 'Date Desc \u{2193}'},
      {value: 'nameAsc', label: 'Name Asc \u{2191}'},
      {value: 'nameDesc', label: 'Name Desc \u{2193}'}
    ]
  }),

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // NOTE: after create new Feed - redirect to view page, and created Feed must be active
      if (from.name === 'feed-create') {
        vm.localSetActiveDiet(vm.filteredDiets[0])
      }
    })
  },

  methods: {
    ...mapActions({
      onDeleteDiet: 'feed/onDeleteDiet',
      onSaveDiet: 'feed/onSaveDiet'
    }),

    ...mapMutations({
      setActiveDiet: 'feed/setActiveDiet',
      setResultMatrix: 'result/setResultMatrix',
      setIngredientToActiveDietAllPhases: 'feed/setIngredientToActiveDietAllPhases',
      unsetIngredientFromActiveDietAllPhases: 'feed/unsetIngredientFromActiveDietAllPhases',
      deleteDiet: 'feed/deleteDiet'
    }),

    isActiveDiet(id) {
      return this.activeDiet.id === id
    },

    onRemoveIngredient(val) {
      this.unsetIngredientFromActiveDietAllPhases({ingredient: val})
    },

    onSelectIngredient(val) {
      this.setIngredientToActiveDietAllPhases({ingredient: val})
    },

    onSave(diet) {
      this.onSaveDiet({diet: {...diet}}).then(res => {
        const savedDiet = {...diet, ...res.diet}

        this.diets.splice(this.getIndex(this.diets, diet.id), 1, savedDiet)
        this.localSetActiveDiet(savedDiet)

        this.$toast.open({
          message: `Diet <strong>${diet.name}</strong> was saved!`
        })
      })
    },

    onDelete() {
      this.onDeleteDiet().then((name) => {
        this.onModalClose()

        if (this.filteredDiets.length) {
          this.localSetActiveDiet(this.filteredDiets[0])
        }

        this.$toast.open({
          type: 'error',
          message: `Diet <strong>${name}</strong> was deleted!`
        })
      })
    },

    localSetActiveDiet(activeDiet) {
      this.setActiveDiet(activeDiet || {})
      this.setResultMatrix({})
    }
  },

  computed: {
    ...mapGetters({
      diets: 'feed/getDiets',
      activeDiet: 'feed/getActiveDiet',
      activeAnimal: 'animalType/getActiveAnimalType',
    }),

    localDiet: {
      get() {
        return this.$store.state.feed.activeDiet
      },

      set(value) {
        this.localSetActiveDiet(value)
      }
    },

    filteredDiets() {
      let localDiets = Array.from(this.diets).filter(diet => diet.animal_id === this.activeAnimal.id)

      if (this.searchQuery) {
        localDiets = localDiets.filter(diet => diet.name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      }

      if (this.sortQuery) {
        localDiets.sort((a, b) => {
          switch (this.sortQuery) {
            case 'dateAsc':
              if (a.created_at < b.created_at) return -1
              break
            case 'dateDesc':
              if (a.created_at > b.created_at) return -1
              break
            case 'nameAsc':
              if (a.name < b.name) return -1
              break
            case 'nameDesc':
              if (a.name > b.name) return -1
              break
          }
        })
      }

      return localDiets
    },

    dietsByAnimalType() {
      return !this.diets.filter(diet => diet.animal_id === this.activeAnimal.id).length
    },

    disabledNextButton() {
      return !Object.keys(this.activeDiet).length
    }
  }
};
</script>

<style scoped>
</style>
