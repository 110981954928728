import { render, staticRenderFns } from "./FeedView.vue?vue&type=template&id=e6114f1c&scoped=true"
import script from "./FeedView.vue?vue&type=script&lang=js"
export * from "./FeedView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e6114f1c",
  null
  
)

export default component.exports