import { render, staticRenderFns } from "./FeedListEmpty.vue?vue&type=template&id=13ff39de&scoped=true"
import script from "./FeedListEmpty.vue?vue&type=script&lang=js"
export * from "./FeedListEmpty.vue?vue&type=script&lang=js"
import style0 from "./FeedListEmpty.vue?vue&type=style&index=0&id=13ff39de&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ff39de",
  null
  
)

export default component.exports