<template>
  <div class="empty-feed-state flex items-center flex-col pt-12 pb-20 mx-auto bg-white rounded-xl text-medium">
    <div class="icon bg-brand-lightest rounded-full mb-6 w-24 h-24"></div>

    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'FeedListEmpty',
  props: {
    text: {
      type: String,
      default: 'Select feed mill to edit'
    }
  }
};
</script>

<style scoped>
  .icon {
    background-image: url("~@/assets/images/icons/ingredients.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
</style>
